@tailwind base;
@tailwind components;
@tailwind utilities;


.glassmorphism1{
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.6px);
    -webkit-backdrop-filter: blur(8.6px);
    }
    

    .maskImage{
            mask-image: url('./components/ui/mask.svg');
            mask-size: 40px;
            mask-repeat: no-repeat;
    }



 
@layer base {
        :root {
          --background: 0 0% 100%;
          --foreground: 20 14.3% 4.1%;
          --card: 0 0% 100%;
          --card-foreground: 20 14.3% 4.1%;
          --popover: 0 0% 100%;
          --popover-foreground: 20 14.3% 4.1%;
          --primary: 47.9 95.8% 53.1%;
          --primary-foreground: 26 83.3% 14.1%;
          --secondary: 60 4.8% 95.9%;
          --secondary-foreground: 24 9.8% 10%;
          --muted: 60 4.8% 95.9%;
          --muted-foreground: 25 5.3% 44.7%;
          --accent: 60 4.8% 95.9%;
          --accent-foreground: 24 9.8% 10%;
          --destructive: 0 84.2% 60.2%;
          --destructive-foreground: 60 9.1% 97.8%;
          --border: 20 5.9% 90%;
          --input: 20 5.9% 90%;
          --ring: 20 14.3% 4.1%;
          --radius: 0.5rem;
          --chart-1: 12 76% 61%;
          --chart-2: 173 58% 39%;
          --chart-3: 197 37% 24%;
          --chart-4: 43 74% 66%;
          --chart-5: 27 87% 67%;
        }
      
        .dark {
          --background: 20 14.3% 4.1%;
          --foreground: 60 9.1% 97.8%;
          --card: 20 14.3% 4.1%;
          --card-foreground: 60 9.1% 97.8%;
          --popover: 20 14.3% 4.1%;
          --popover-foreground: 60 9.1% 97.8%;
          --primary: 47.9 95.8% 53.1%;
          --primary-foreground: 26 83.3% 14.1%;
          --secondary: 12 6.5% 15.1%;
          --secondary-foreground: 60 9.1% 97.8%;
          --muted: 12 6.5% 15.1%;
          --muted-foreground: 24 5.4% 63.9%;
          --accent: 12 6.5% 15.1%;
          --accent-foreground: 60 9.1% 97.8%;
          --destructive: 0 62.8% 30.6%;
          --destructive-foreground: 60 9.1% 97.8%;
          --border: 12 6.5% 15.1%;
          --input: 12 6.5% 15.1%;
          --ring: 35.5 91.7% 32.9%;
          --chart-1: 220 70% 50%;
          --chart-2: 160 60% 45%;
          --chart-3: 30 80% 55%;
          --chart-4: 280 65% 60%;
          --chart-5: 340 75% 55%;
        }
      }
      
       
      @layer base {
        * {
          @apply border-border;
        }
        body {
          @apply bg-background text-foreground;
          font-feature-settings: "rlig" 1, "calt" 1;
        }
      }