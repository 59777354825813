body{
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  
}


.bg-image{
  background-image:url('./assets/svgimage.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.stroke{
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 3px;
}

.orange{
  width: 18.75rem;
  height: 18.75rem;
  flex-shrink: 0;
  border-radius: 18.75rem;
opacity: var(--1, 1);
background: #F2751A;
box-shadow: 0px 4px 30.4px 0px #F2751A;
}

.orange-2{
  width: 7.1875rem;
height: 7.1875rem;
flex-shrink: 0;
border-radius: 18.75rem;
opacity: var(--1, 1);
background: #F2751A;
box-shadow: 0px 4px 30.4px 0px #F2751A;
}

.orange-3{
  width: 3.1875rem;
height: 3.1875rem;
flex-shrink: 0;
border-radius: 18.75rem;
opacity: var(--1, 1);
background: #F2751A;
box-shadow: 0px 4px 30.4px 0px #F2751A;
}


.yellow{
  width: 18.75rem;
height: 18.75rem;
flex-shrink: 0;
border-radius: 18.75rem;
opacity: var(--1, 1);
background: #C5FB67;
box-shadow: 0px 4px 30.4px 0px #C5FB67;
}
.yellow-2{
  width: 3.75rem;
height: 3.75rem;
flex-shrink: 0;
border-radius: 18.75rem;
opacity: var(--1, 1);
background: #C5FB67;
box-shadow: 0px 4px 30.4px 0px #C5FB67;
}
.bluegr{
  width: 45.5625rem;
height: 43.4375rem;
flex-shrink: 0;
border-radius: 45.5625rem;
opacity: var(--1, 1);
background: #000342;
filter: blur(330.8500061035156px);
}
/* Reset some default styles for the body */
/* Your CSS file */
.border-gradient {
  border-image: linear-gradient(to right, #FFFFFF, #0031A7);
  border-image-slice: 1;
  border-radius: 45px;
}
.swip{
  width: 6.75rem;
  height: 6.75rem;
  flex-shrink: 0;
  border-radius: 18.75rem;
  opacity: var(--1, 1);
  background: #000342;
  box-shadow: 0px 4px 30.4px 0px #000342;
}

.cursor-circle {
  position: fixed;
  width: 40px;
  height: 40px;
  border: 2px solid #C5FB67; /* Adjust border styles as needed */
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure the circle is above other elements */
  background-color: transparent; /* Set background color as needed */
}
.stroke2{
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #C5FB67;
  -webkit-text-stroke-width: 1px;
}
.whitegr{
  width: 86.87838rem;
height: 7.79094rem;
transform: rotate(-23.343deg);
flex-shrink: 0;
border-radius: 1.1875rem;
opacity: var(--1, 1);
background: #FFF;
filter: blur(300px);
}

.stroke3{
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color:#FFF;
  -webkit-text-stroke-width: 1px;
}

.btn {
  width: 140px;
  height: 50px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.btn span {
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: top 0.5s;
}

.btn-text-one {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.btn-text-two {
  position: absolute;
  width: 100%;
  top: 150%;
  left: 0;
  transform: translateY(-50%);
}

.btn:hover .btn-text-one {
  top: -100%;
}

.btn:hover .btn-text-two {
  top: 50%;
}

.about-glass{
  width: 7.1875rem;
height: 7.1875rem;
flex-shrink: 0;
border-radius: 18.75rem;
opacity: var(--1, 1);
background: #F2751A;
box-shadow: 0px 4px 30.4px 0px #F2751A;
}

.about-glass2{
  width: 18.75rem;
  height: 18.75rem;
  flex-shrink: 0;
  border-radius: 18.75rem;
  opacity: var(--1, 1);
  background: #F2751A;
  box-shadow: 0px 4px 30.4px 0px #F2751A;
}
.team-bg{
flex-shrink: 0;
border-radius: 2.8125rem;
opacity: var(--1, 1);
background: linear-gradient(180deg, rgba(197, 251, 103, 0.80) 0%, rgba(212, 255, 136, 0.00) 100%);
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;

}


.glassmorphism{
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.6px);
  -webkit-backdrop-filter: blur(8.6px);
  border: 1px solid rgba(255, 255, 255, 0.33);
  }
  
  

  .centerPostion{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .centerPostion1{
   
    left: 50%;
    transform: translate(-50%, 0);
  }
  .invisible-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .gradient{
    background: rgb(242,117,26);
background: linear-gradient(111deg, rgba(242,117,26,1) 0%, rgba(197,251,103,1) 100%);
  }


  .hamburger {
    cursor: pointer;
  }
  
  .hamburger input {
    display: none;
  }
  
  .hamburger svg {
    /* The size of the SVG defines the overall size */
    height: 2.5em;
    /* Define the transition for transforming the SVG */
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .line {
    fill: none;
    stroke: black;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2.4;
    /* Define the transition for transforming the Stroke */
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .line-top-bottom {
    stroke-dasharray: 12 63;
  }
  
  .hamburger input:checked + svg {
    transform: rotate(-45deg);
  }
  
  .hamburger input:checked + svg .line-top-bottom {
    stroke-dasharray: 20 300;
    stroke-dashoffset: -32.42;
  }
  