.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #C5FB67;
     color:black;
    z-index: 20;
    
    .bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #C5FB67;
        z-index: 30;
    }

    .links {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 80;
        gap: 20px;
    }

button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top:30px;
    right:40px;
    background-color: #C5FB67;
    border:none;
    cursor: pointer;
    z-index: 50;
}

}